import React, { useState } from "react";
import styled from "styled-components";
import { QuaternaryButton } from "../buttons/buttons";

const Wrapper = styled.div`
  .notification-read {
    display: none;
  }
  .notification-cleared {
    padding: 1rem 0 0 0;
    margin: 0;
  }
`;

const Container = styled.section`
  padding: 2rem 3rem 0 3rem;
  margin: 0 0 2rem 0;
  transition: all 250ms;
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    padding: 2rem 1.5rem 0 1.5rem;
  }
`;

const Card = styled.article`
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: end;
  padding: 2rem 3rem;
  transition: all 1000ms ease 0s;
  background-size: 150%;
  background-image: linear-gradient(150deg, rgb(0, 43, 255), rgb(255, 77, 29));
  position: relative;
  h3 {
    padding: 0 0 0.75rem 0;
    font-size: 1.875rem;
  }
  p {
    font-weight: 200;
  }
  &:hover {
    background-position: 90% 50%;
  }
  .content {
    padding: 0 2rem 0 0;
  }
  .learn-more {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .close-button {
    position: absolute;
    inset: 1.25rem 0.75rem auto auto;
    transform: rotate(45deg);
    background: none;
    border: none;
    cursor: pointer;
    span.line {
      background: #fff;
      height: 1px;
      width: 20px;
      display: block;
      transition: all 250ms;
    }
    span.cross {
      transform: rotate(90deg) translate(-1px, 0px);
    }
    &:hover {
      span.line {
        background: ${(props) => props.theme.colors.secondaryColors.orange};
      }
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    grid-template-columns: 1fr;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    padding: 2rem;
    .learn-more {
      padding: 1rem 0 0 0;
    }
    .close-button {
      span.line {
        width: 25px;
      }
    }
  }
`;

const Notifications = (props) => {
  const [notificationRead, setNotificationRead] = useState(null);
  const [updateContainer, setUpdateContainer] = useState(null);

  // Replace with your own function for marking this as closed
  const closeNotification = () => {
    console.log("Close notification");
    setNotificationRead("notification-read");
    setUpdateContainer("notification-cleared");
  };

  return (
    <Wrapper>
      <Container className={updateContainer}>
        <Card className={notificationRead}>
          <div className="content">
            <h3>{props.notificationData.title}</h3>
            <p>{props.notificationData.description}</p>
          </div>
          <div className="learn-more">
            <button className="close-button" onClick={closeNotification}>
              <span className="line"></span>
              <span className="line cross"></span>
            </button>
            <QuaternaryButton
              title={props.notificationData.call_to_action.title}
              link={props.notificationData.call_to_action.link}
              target={"_blank"}
            />
          </div>
        </Card>
      </Container>
    </Wrapper>
  );
};

export default Notifications;
