import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import SortIcon from "../../images/icons/sort.svg";

const SortButton = styled.div`
  button.sort-button {
    border: none;
    background: none;
    color: ${(props) => props.theme.variables.color};
    margin: 0 0 0 1rem;
    display: flex;
    align-items: center;
    path {
      transition: all 250ms;
    }
    .sort-icon {
      margin: 0 0 0 0.5rem;
    }
    &:hover {
      color: ${(props) => props.theme.colors.secondaryColors.orange};
      path {
        fill: ${(props) => props.theme.colors.secondaryColors.orange};
      }
    }
  }
  .sort-menu {
    position: relative;
    display: inline-block;
  }
  #sort-menu-content-hide,
  #sort-menu-content-show {
    display: none;
    position: absolute;
    margin: 0.5rem 0 0 0.75rem;
    background: ${(props) => props.theme.variables.menus.background};
    border: 1px solid ${(props) => props.theme.variables.menus.border};
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.75);
    z-index: 1;
  }
  #sort-menu-content-show {
    display: block;
  }
  .left-oriented-menu {
    inset: auto 0 auto auto;
  }
  #select-sort-button {
    background: transparent;
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
    font-size: 0.75rem;
    color: ${(props) => props.theme.variables.color};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    &:hover {
      background: ${(props) =>
        props.theme.variables.inputs.select.hoverBackground};
    }
    .selected-checkmark {
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: 1px solid ${(props) => props.theme.variables.color};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      display: none;
    }
    .show {
      display: block;
      visibility: visible;
    }
  }
  .right-oriented-button {
    min-width: 125px;
  }
  .left-oriented-button {
    min-width: 165px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    #sort-menu-content-show {
      inset: auto 0 auto auto;
    }
  }
`;

const Sort = (props) => {
  // Translation component
  const { t } = useTranslation();

  // Display project sorting options in menu dropdown
  const [sortMenuClass, setSortMenuClass] = useState("sort-menu-content-hide");
  const toggleSortOptions = () => {
    if (sortMenuClass === "sort-menu-content-hide") {
      setSortMenuClass("sort-menu-content-show");
    } else {
      setSortMenuClass("sort-menu-content-hide");
    }
  };

  // Select project sort option
  const [sortSelectionTitle, setSortSelectionTitle] = useState(t("sort"));
  const selectSortOption = (sortSelection, selectionIndex) => {
    setSortSelectionTitle(sortSelection);
    setSortMenuClass("sort-menu-content-hide");
    const selectButtonArray = document.querySelectorAll("#select-sort-button");
    const selectedCheckmark = document.querySelectorAll(".selected-checkmark");
    selectButtonArray.forEach((button, index) => {
      if (selectionIndex === index) {
        selectedCheckmark[index].classList.add("show");
      } else if (selectedCheckmark[index].classList.contains("show")) {
        selectedCheckmark[index].classList.remove("show");
      }
    });

    // activating the sort function
    props.updateRecoverProjects(sortSelection);
  };

  return (
    <SortButton>
      <div className="sort-menu">
        <button className="sort-button" onClick={toggleSortOptions}>
          {sortSelectionTitle}{" "}
          <SortIcon className="sort-icon" alt="Sort icon triangle" />
        </button>
        <div
          id={sortMenuClass}
          className={
            props.sortOptions.menu_display_direction === "left"
              ? "left-oriented-menu"
              : null
          }
        >
          <ul>
            {props.sortOptions.options.map((optionItem, index) => {
              return (
                <li key={optionItem.id}>
                  <button
                    id="select-sort-button"
                    onClick={() => selectSortOption(optionItem.title, index)}
                    className={
                      props.sortOptions.menu_display_direction === "left"
                        ? "left-oriented-button"
                        : "right-oriented-button"
                    }
                  >
                    {optionItem.title} <span className="selected-checkmark" />
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </SortButton>
  );
};

export default Sort;
