import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import Layout from "../components/layout";
import { userRoles } from "../components/utilities/utilities";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { addComma } from "../components/utilities/utilities";
import Notifications from "../components/layouts/dashboard-notifications";
import {
  TotalLeaksFoundCard,
  LeaksPerMileCard,
  NonSurfacingLeaksCard,
  GreenhouseGasReductionCard,
  WaterSavedCard,
  WaterCostSavingsCard,
  EnergySavedCard,
  EnergyCostSavingsCard,
} from "../components/cards/performance-cards";
import {
  RecoverCard,
  MasterplanCard,
  EarthworksCard,
  NewProjectCard,
  NewRecoverProjectCard,
} from "../components/cards/project-cards";
import SortButton from "../components/buttons/sort-button";
import { MasterDashboardButton } from "../components/buttons/buttons";
import Checkbox from "../components/buttons/card-checkbox";
import RecoverIcon from "../images/icons/recover.svg";
import MasterPlanIcon from "../images/icons/masterplan.svg";
import EarthWorksIcon from "../images/icons/earthworks.svg";
import InformationIcon from "../images/icons/information.svg";
// import ScrollToBottom from "../images/scrollToBottom.png";
// import ScrollToTop from "../images/scrollToTop.png";

// Replace with your own data source
import Data from "../data/data";
//import { AuthContext } from "../store/authContext";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  addSubProject,
  setSubProjectRecoverArr,
  setProjectData,
  setAllProjects,
  setLoading,
  setMetricOrUs,
} from "../store/ducks/general";
import { overall } from "../store/ducks/recover";
import UserRoute from "../components/routes/userRoute";

const Container = styled.div`
  width: 80%;
  padding: 2rem 0 2rem 0;
  position: absolute;
  h2,
  h3 {
    font-size: 1.875rem;
  }
  h4 {
    font-weight: 600;
  }
  .title-row {
    padding: 1rem 3rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid
      ${(props) => props.theme.colors.uiPalette.dark.highGray};
    .icon {
      width: 35px;
      margin: 0 0.75rem 0 0;
    }
  }
  .projects-row {
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    .title-row {
      padding: 1rem 1.5rem;
    }
  }
`;

const Recover = styled.section`
  .overall-performance-row {
    padding: 2rem 3rem;
    display: flex;
    justify-content: space-between;
  }
  .overall-performance-column {
    display: flex;
    align-items: center;
    .icon {
      margin: 0 0 0 0.75rem;
      path,
      #shape {
        transition: all 400ms;
      }
      &:hover {
        path {
          fill: ${(props) => props.theme.colors.secondaryColors.orange};
        }
        #shape {
          stroke: ${(props) => props.theme.colors.secondaryColors.orange};
        }
      }
    }
  }
  .edit-button {
    font-size: 0.8125rem;
    font-weight: 500;
    border: none;
    background: none;
    color: ${(props) => props.theme.variables.color};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    .icon {
      margin: 0 0.5rem 0;
    }
    path {
      transition: all 250ms;
    }
    &:hover {
      color: ${(props) => props.theme.colors.secondaryColors.orange};
      path {
        fill: ${(props) => props.theme.colors.secondaryColors.orange};
      }
    }
  }
  .recover-card-row {
    display: grid;
    grid-template-columns: 10% 90%;
    transition: all 250ms;
    padding: 0.5rem;
    margin: 0.5rem 0;
    &:hover {
      background: ${(props) =>
        props.theme.variables.cards.project.hoverBackground};
      .recover-checkbox-container {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .activate-row {
    background: ${(props) =>
      props.theme.variables.cards.project.hoverBackground};
    .recover-checkbox-container {
      visibility: visible;
      opacity: 1;
    }
  }
  .overall-performance-card-row {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(4, 1fr);
    padding: 0rem 3rem;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    .overall-performance-card-row {
      grid-template-columns: repeat(2, 1fr);
      padding: 0.5rem 1.5rem;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    .overall-performance-row {
      padding: 1rem 1.5rem;
    }
    .edit-button-text {
      display: none;
    }
  }
`;

const MasterPlan = styled.section``;

const EarthWorks = styled.section``;

const Projects = styled.section`
  padding: 2rem 3rem;
  .projects-title-row {
    padding: 1rem 0 0 7rem;
    margin: 0 0.5rem;
    display: flex;
    justify-content: space-between;
  }
  .projects-sort-column {
    display: flex;
    align-items: center;
  }
  .recover-checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xl}) {
    .projects-title-row {
      padding: 1rem 0 0 5rem;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    padding: 1rem 1.5rem;
    .projects-title-row {
      padding: 2rem 0 0 0;
      margin: 0;
      flex-flow: column;
    }
    .projects-sort-column {
      justify-content: space-between;
      padding: 0 0 1rem 0;
    }
    .recover-checkbox-container {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }
`;

const style = {
  masterplanEarthworks: {
    width: "90%",
    marginLeft: "10%",
    padding: "0.5rem",
  },
};

const Home = () => {
  const [projectState, setProjectState] = useState();
  const [overallPerformance, setOverallPerformance] = useState();
  const [isOverallPerformance, setIsOverallPerformance] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useRef();
  const masterButtonRef = useRef(null);
  const endOfPage = useRef();
  const startOfPage = useRef();

  const dispatch = useDispatch();
  const loginValues = useSelector((state) => state.general.loginValues);

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) =>
      setIsIntersecting(entry.isIntersecting)
    );
  }, []);

  const useIsInViewport = (ref) => {
    useEffect(() => {
      observer.current.observe(ref.current);
      return () => {
        observer.current.disconnect();
      };
    }, [ref, observer.current]);

    return isIntersecting;
  };

  useEffect(async () => {
    if (loginValues && loginValues.token != null) {
      try {
        const storedRegion = localStorage.getItem("region");
        if (storedRegion === null) {
          await getRegion();
        }
        await fetchHomeData();
      } catch (err) {
        localStorage.removeItem("auth");
        localStorage.removeItem("subProjectData");
        localStorage.removeItem("subProjects");
        localStorage.removeItem("subMenu");
        localStorage.removeItem("ally-supports-cache");
        navigate("/");
      }
    }
  }, [loginValues.token]);

  // Runs when the data for the recover pulled from the DB
  useEffect(() => {
    // Sorting all the projects according to the updated_at attribute - Default Sort
    setProjectState({
      ...projectState,
      recover: projectState?.recover?.sort((a, b) => {
        const strA = a.title.toUpperCase();
        const strB = b.title.toUpperCase();
        if (strA === strB) {
          return 0;
        }

        if (strA > strB) {
          return 1;
        }

        return -1;
      }),
    });
    // Creating a new array for the projectState.recover array that only includes date, title, gis_files, leaksheet_dataform, u_view_map and sub_project
    let subProjectRecoverArr = [];

    // Getting array data from projectState.recover
    for (let i = 0; i < projectState?.recover?.length; i++) {
      const recover = projectState.recover[i];

      subProjectRecoverArr.push({
        title: recover.title,
        date: recover.date,
        sub_project: recover.sub_project,
        gis_files: recover.gis_files,
        leaksheets_dataform: recover.leaksheets_dataform,
        u_view_map: recover.u_view_map,
      });

      localStorage.setItem("subProjects", JSON.stringify(subProjectRecoverArr));
    }

    // Setting the subProjectRecoverArr to the redux
    dispatch(setSubProjectRecoverArr(subProjectRecoverArr));

    dispatch(setAllProjects(projectState));
  }, [projectState?.masterPlan]);

  const updateRecoverProjects = async (sortSelection) => {
    setProjectState({
      ...projectState,
      recover: projectState?.recover?.slice().sort((a, b) => {
        if (sortSelection === "Most Recent") {
          if (a.most_recent === b.most_recent) {
            return 0;
          }

          if (a.most_recent < b.most_recent) {
            return 1;
          }

          return -1;
        } else if (sortSelection === "Oldest") {
          if (a.oldest === b.oldest) {
            return 0;
          }

          if (a.oldest > b.oldest) {
            return 1;
          }

          return -1;
        } else if (sortSelection == "Last Used") {
          if (a.last_used === b.last_used) {
            return 0;
          }

          if (a.last_used < b.last_used) {
            return 1;
          }

          return -1;
        } else if (sortSelection === "Name A-Z") {
          const strA = a.title.toUpperCase();
          const strB = b.title.toUpperCase();
          if (strA === strB) {
            return 0;
          }

          if (strA > strB) {
            return 1;
          }

          return -1;
        } else if (sortSelection === "Name Z-A") {
          const strA = a.title.toUpperCase();
          const strB = b.title.toUpperCase();
          if (strA === strB) {
            return 0;
          }

          if (strA < strB) {
            return 1;
          }

          return -1;
        }
      }),
    });
  };

  const getRegion = async () => {
    try {
      const { data } = await axios.get(`/region`, {
        params: {
          id: loginValues.typeId,
          role: loginValues.role,
          userId: loginValues.userId,
        },
      });
      if (data === "NA") {
        dispatch(setMetricOrUs("Imperial US"));
      }
      localStorage.setItem(
        "general",
        JSON.stringify({ isMetricOrUs: "Imperial US" })
      );
      localStorage.setItem("region", data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchHomeData = async () => {
    let isMounted = true;

    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(`/home-data`, {
        params: {
          type: loginValues.type,
          typeId: loginValues.typeId,
          role: loginValues.role,
          userId: loginValues.userId,
        },
      });

      const { overallPerformanceRecover, projectsList } = data;
      if (!projectsList || !overallPerformanceRecover) {
        console.log("do something");
      }
      if (isMounted) {
        setProjectState(projectsList);
        if (overallPerformanceRecover) {
          dispatch(overall(overallPerformanceRecover));
          setOverallPerformance(overallPerformanceRecover);
          setIsOverallPerformance(true);
        }
        dispatch(setLoading(false));
      }
    } catch (err) {
      console.log(err);
    }
    return () => {
      isMounted = false;
    };
  };

  const goToSubProject = (subProjectData) => {
    dispatch(setProjectData(subProjectData));
    dispatch(addSubProject(subProjectData.sub_project));
    window.localStorage.setItem(
      "auth",
      JSON.stringify({ ...loginValues, subProject: subProjectData.sub_project })
    );
    window.localStorage.setItem(
      "subProjectData",
      JSON.stringify(subProjectData)
    );
    navigate("../u-collect-dashboard/dashboard");
  };

  const goToMasterPlan = (subProjectData) => {
    dispatch(addSubProject(subProjectData.sub_project));
    dispatch(setProjectData(subProjectData));
    window.localStorage.setItem("auth", JSON.stringify({ ...loginValues }));
    window.localStorage.setItem(
      "subProjectData",
      JSON.stringify(subProjectData)
    );
    navigate("../../masterPlan");
  };

  // Navigate to Master Dashboard with selected POI sites
  const [selectedRecoverSiteNumber, setSelectedRecoverSiteNumber] = useState(0);
  const [allSelectedRecoverSites, setAllSelectedRecoverSites] = useState([]);
  const recoverCheckboxChecked = (
    e,
    selectedRecoverCard,
    selectedCardIndex
  ) => {
    const checked = e.target.checked;
    const allRecoverCardRows = document.querySelectorAll("#recover-row");
    if (checked) {
      setSelectedRecoverSiteNumber(selectedRecoverSiteNumber + 1);
      setAllSelectedRecoverSites((currentList) => [
        ...currentList,
        selectedRecoverCard,
      ]);
      allRecoverCardRows.forEach((row, index) => {
        if (selectedCardIndex === index) {
          allRecoverCardRows[index].classList.add("activate-row");
        }
      });
    } else {
      setSelectedRecoverSiteNumber(selectedRecoverSiteNumber - 1);
      setAllSelectedRecoverSites(
        allSelectedRecoverSites.filter(
          (recoverSite) => recoverSite.title !== selectedRecoverCard.title
        )
      );
      allRecoverCardRows.forEach((row, index) => {
        if (selectedCardIndex === index) {
          allRecoverCardRows[index].classList.remove("activate-row");
        }
      });
    }
  };

  // Translation component
  const { t } = useTranslation();

  /*
   loading projects list requires - credentials data (username and password) 
   and u view map, GIS files, WMS link and leaksheets & dataform and progressbar completion
  */
  return (
    <>
      <UserRoute>
        <Layout goToSubProject={goToSubProject} projectState={projectState}>
          <span ref={startOfPage}></span>
          <Container>
            <Recover>
              <div className="title-row">
                <RecoverIcon className="icon" />
                <h1>Recover</h1>
              </div>
              <div className="overall-performance-row">
                <div className="overall-performance-column">
                  <h4>{t("recover.h4")}</h4>
                  <InformationIcon
                    data-tip
                    data-for="overall-performance-information"
                    className="icon"
                  />
                </div>
              </div>
              <ReactTooltip
                id="overall-performance-information"
                type="dark"
                place="top"
                effect="solid"
                className="tooltips"
                backgroundColor={"#393939"}
              >
                <p>{t("recover.overall-performance.tooltip")}</p>
              </ReactTooltip>
              {isOverallPerformance && (
                <div className="overall-performance-card-row">
                  <TotalLeaksFoundCard
                    pdf={false}
                    totalLeaksFound={overallPerformance.totalLeaksFound}
                  />
                  <NonSurfacingLeaksCard
                    pdf={false}
                    totalLeaksFound={overallPerformance.totalLeaksFound}
                    nonSurfacingLeaks={overallPerformance.nonSurfacingLeaks}
                    width="standard"
                  />
                  <LeaksPerMileCard
                    pdf={false}
                    totalLeaksFound={overallPerformance.totalLeaksFound}
                    pipeInvestigated={overallPerformance.pipeInvestigated}
                    leaksPerkmMile={
                      overallPerformance.totalLeaksFound /
                      overallPerformance.pipeInvestigated
                    }
                  />
                  <GreenhouseGasReductionCard
                    pdf={false}
                    greenhouseSaved={
                      overallPerformance.greenhouseGasReductionWithWo
                    }
                  />
                  <WaterSavedCard
                    pdf={false}
                    waterSaved={overallPerformance.waterSavedWithWo}
                  />
                  <WaterCostSavingsCard
                    pdf={false}
                    waterCostSaved={overallPerformance.waterCostSavingsWithWo}
                  />
                  <EnergySavedCard
                    pdf={false}
                    energySaved={overallPerformance.energySavedWithWo}
                  />
                  <EnergyCostSavingsCard
                    pdf={false}
                    energyCostSaved={overallPerformance.energyCostSavingsWithWo}
                  />
                </div>
              )}
              <Projects>
                <div className="projects-title-row">
                  <div className="projects-sort-column">
                    <h4>{t("recover.projects.h4")}</h4>
                    <SortButton
                      updateRecoverProjects={updateRecoverProjects}
                      sortOptions={{
                        options: [
                          {
                            id: 0,
                            title: t("sort.most-recent"),
                          },
                          {
                            id: 1,
                            title: t("sort.oldest"),
                          },
                          {
                            id: 2,
                            title: t("sort.a-z"),
                          },
                          {
                            id: 3,
                            title: t("sort.z-a"),
                          },
                          {
                            id: 4,
                            title: t("sort.last-used"),
                          },
                        ],
                        menu_display_direction: "right",
                      }}
                    />
                  </div>
                  <div ref={masterButtonRef}>
                    {loginValues &&
                      loginValues.role !== userRoles.CLIENT_VIEWER && (
                        <MasterDashboardButton
                          recoverProjectData={{
                            link: "/master-dashboard",
                            totalSelected: selectedRecoverSiteNumber,
                            selectedSites: allSelectedRecoverSites,
                          }}
                        />
                      )}
                  </div>
                </div>

                <div className="projects-content-row">
                  {loginValues &&
                    loginValues.role &&
                    projectState &&
                    projectState.recover &&
                    projectState.recover.map((value, index) => {
                      return (
                        <div
                          key={index}
                          className="recover-card-row"
                          id="recover-row"
                        >
                          <div className="recover-checkbox-container">
                            <Checkbox
                              checkboxFunction={(e) =>
                                recoverCheckboxChecked(e, value, index)
                              }
                            />
                          </div>
                          <RecoverCard
                            goToSubProject={goToSubProject}
                            projectData={value}
                            role={loginValues.role}
                          />
                        </div>
                      );
                    })}
                  {loginValues &&
                    loginValues.role != userRoles.CLIENT_VIEWER && (
                      <NewRecoverProjectCard link={"/request-new-project"} />
                    )}
                </div>
              </Projects>
            </Recover>
            {projectState &&
              projectState.masterPlan &&
              projectState.masterPlan.length > 0 && (
                <MasterPlan>
                  <div className="title-row">
                    <MasterPlanIcon className="icon" />
                    <h2>MasterPlan</h2>
                  </div>
                  <Projects>
                    <div className="projects-title-row">
                      <h4>{t("masterplan.h4")}</h4>
                    </div>

                    <div>
                      {loginValues &&
                        loginValues.role &&
                        projectState &&
                        projectState.masterPlan &&
                        projectState.masterPlan.map((value, index) => {
                          return (
                            <div style={style.masterplanEarthworks} key={index}>
                              <br />
                              <MasterplanCard
                                projectData={value}
                                goToMasterPlan={goToMasterPlan}
                                role={loginValues.role}
                              />
                            </div>
                          );
                        })}
                      {loginValues &&
                        loginValues.role != userRoles.CLIENT_VIEWER && (
                          <NewProjectCard link={"/request-new-project"} />
                        )}
                    </div>
                  </Projects>
                </MasterPlan>
              )}
            {projectState &&
              projectState.earthworks &&
              projectState.earthworks.length > 0 && (
                <EarthWorks>
                  <div className="title-row">
                    <EarthWorksIcon className="icon" />
                    <h2>EarthWorks</h2>
                  </div>
                  <Projects>
                    <div className="projects-title-row">
                      <h4>{t("earthworks.h4")}</h4>
                    </div>

                    <div className="projects-content-row">
                      {loginValues &&
                        loginValues.role &&
                        projectState &&
                        projectState.earthworks &&
                        projectState.earthworks.map((value, index) => {
                          return (
                            <div style={style.masterplanEarthworks} key={index}>
                              <br />
                              <EarthworksCard
                                role={loginValues.role}
                                key={index}
                                projectData={value}
                              />
                            </div>
                          );
                        })}
                      {loginValues &&
                        loginValues.role != userRoles.CLIENT_VIEWER && (
                          <NewProjectCard link={"/request-new-project"} />
                        )}
                    </div>
                  </Projects>
                </EarthWorks>
              )}
            <span ref={endOfPage}></span>
          </Container>
        </Layout>
      </UserRoute>
      {/* <div
        style={{
          display: "flex",
          position: "relative",
          justifyContent: "end",
          alignItems: "end",
          height: "100%",
          width: "3%",
          paddingTop: "50.25rem",
          paddingRight: "0.5rem",
          marginLeft: "97%",
          zIndex: "999",
        }}
      >
        {!useIsInViewport(endOfPage) && (
          <button
            style={{
              color: "white",
              position: "fixed",
            }}
            onClick={() => {
              endOfPage.current.scrollIntoView();
            }}
          >
            <img style={{ backgroundColor: "white" }} src={ScrollToBottom} />
          </button>
        )}
      </div> */}
    </>
  );
};
export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: [
            "common"
            "home"
            "preformance"
            "measurements"
            "buttons"
            "project-cards"
            "navigation"
          ]
        }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
