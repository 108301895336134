import React from "react";
import styled from "styled-components";

const Container = styled.label`
  display: block;
  height: 20px;
  width: 20px;
  position: relative;
  cursor: pointer;
  transform: scale(0.75);
  /* Reset browser default */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  /* Create custom checkbox */
  .checkmark {
    border: 1px solid
      ${(props) => props.theme.variables.buttons.checkbox.border};
    position: absolute;
    inset: 0;
    height: 20px;
    width: 20px;
    transition: all 250ms;
  }
  &:hover input ~ .checkmark {
    border: 1px solid
      ${(props) => props.theme.variables.buttons.checkbox.hoverBorder};
  }
  input:checked ~ .checkmark {
    background: ${(props) => props.theme.variables.buttons.checkbox.checked};
    border: 1px solid
      ${(props) => props.theme.variables.buttons.checkbox.checked};
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const Checkbox = (props) => {
  return (
    <Container>
      <input type="checkbox" onChange={props.checkboxFunction}/>
      <span className="checkmark" />
    </Container>
  );
};

export default Checkbox;
